$primary-color: #01b7f2;
$secondary-color: #FDB715;
$buttoncolor: #e46c6d;
$calendarColor: #FFF;
$fourth-color: #4B6D93;
$fifth-color: #F97134;
$sixth-color: #2D3E52;
$gutterCarousel: 30px;
$formField-input: #4B6D93;
$border-radius: 10px;
$CTAColour: #85b141;
$phoneColor: #fdb714;
$FlightGreenButton: #98ce44;

//For FAQs-variables
$txtColor:#2B4767;
$ColorTitle: #FFFFFF;
$TxtColor:#A7E04F;
$ParaTextClr:#838383;
$modalborderradius:10px;
$modalinputfieldheight:45px;
$CustomerBorderRadius:15px;
$quotetxtClr:#F1813E;
$blockbg:#FDB7150F;
$boxborderbottomClr:#DBF6FF;
$letter-spacing:0.66px;
$latoFontweightRegular:400;
$PrimaryClrLight:rgba(1, 183, 242, 1);
$imgSizeBox: 59px;
$TitleBordrClr:#707070;
$borderSize:1px;



$MobileGreenColor:  #9ECC57;
$CTALightGreen: #96ca46;
$CTAColourFnt : #FFF;
$box-shadow: 0 1px 5px rgba(0, 0, 0, .08);
$font-familylatoblack:'Lato-Black';
$font-familylato: 'lato';
$font-family: 'lato', san-serif;

$font-LatoBlack: 'lato-black', 'lato', san-serif;
$font-latoRegular: 'Lato-Regular', 'lato', san-serif;
$font-familyNexusBlack: 'NexaBlack', san-serif;
$font-familyNexusRegular: 'nexaRegular', san-serif;
$font-familyNexusLight: 'nexaLight', 'lato', sans-serif;
$min-widthCustomWidth: 165px;
$whiteColor: #FFF;
$width-trigger: 70px;
///tabs
$tabBackground: #4B6D93;
$tabActiveColor: #fff;
$tabBackgroundMobile: #FFF;
$border-radius-tabs: 10px;
$tabPadding: 8px 30px 7px;
$tabNotActive: #7c99bc;
$tabmargin: 5px;
$boxshadow-tab:   0 15px 11px rgba(0, 0, 0, 0.12);
$tabSaveBox: #3cc8f5;
//megamenu 
//toplevel hover over
$toplevelhoverBg : #ddf6fd;
$toplevelhoverCl: #000;

//layout 
$paddingleft : 15px;
$paddingright: 15px;
$paddingtop: 20px;

// Mobile menu

$mobilePrimaryColour: #FFF;
$mobileprimaryFntColour: #7C99BC;
$mobileMenuSecondaryColour: #7C99BC;
$paddingTop: 20px;
$paddingBottom: 20px;


// topsection styles 
$topHeader: #A1A1A1;
$uspboxHeight: 200px;
// Form
$formContainer: #FFF;
$formMobileInputFieldColor: #FFF;
$mobileHeightButtons: 50px;
$marginformLabel: 5px;
$marginformfield: 20px;
$selectorbgColor: #FFF;
$formplaceholderOpacity: 0.5;
$frmFnt: 1.41667em;
// Calendar
$activeCircleFnt: #FFF;
$priceRange: #838383;
$calendarCancel: #838383;
$closeBtn: #838383;
$closeBtnText: #FFF;
$calendarPadding: 0 20px;
$PriceColour: #000;
$marginTopSection: 50px;

// passenger popup
$passenger-fnt: 1.250em;

// Form Fields
$formInputFnt: 1.41667em;

//svg border color
$cross-icon-border:#cfcfcf;

//calendar
$inputfieldflight:#2d3e52;
$datepickerbody:#111;
$datepickerspan:#333;
$banner-color:#FFD256;
$altbg: #efefef;
$contentSpacing: 25px;

$breadcrumbcolor: #B2BAC4;
$border-color-flight: #dbf6ff;

//flight-upgrade 
$upgrade-yellow:#FFF134;
$tab-standard:#01A0F2;
$tab-premium:#0182F2;
$tab-economoyflex:#0159F2;
$tab-business:#DB7C00;
$tab-businessflex:#DBA73E;
$upgrade-box:50px;
$passenger-color:#606060;
$airline-small-color:#8D8D8D;
$dark-sixth-color:#2D3E52;



//flight 
$flight-border-color:#5AD0F6;
$border-flight-main:#01B7F2CC;
$flighttextcolorgreen:#98CE44;
$flightbox-activeColor:#dbf6ff85;
$flight-input-border:#01B7F291;
$flight-disabled-buttoncolor:#CECECE;
$flight-bgcolor:#f8f8f8;
$modal-arrow-bordercolor:#E5E5E5;
$calendar-disabled-color:#CBCBCB;
$baggage-price:#C5F27F;
$flight-value:#666;
$avgpricecolor: #7db921;
$hotelimage-bgcolor:rgba(0,183,241,.78);
$meal-color:#000;
$padding:10px;
$modal-arrows-color:#DFDFDF;
$mandatory-color:#e01a33;
$baggage-image-icon-light-blue:#ccf0fb;
$baggage-image-icon-dark-blue:#2380ed;
$notification-bgcolor:#F6BA2E;
$travel-listing-bgcolor: #01A1D5;
$baggage-panel-border:#B2ECFF;
$radio-button-bg-color: #A4E8FF;
$travel-popup-activeColor: #01B7F217;
$travel-border-color:#BCECFC;
$baggage-bgcolor: #E6E6E6;
$travel-baggage-labelcolor: #A8A8A8;
$crossColor:#8DDEF8;
$choice-activeColor: #00A9DF;
$choice-headingColor: #598416;
$button-selectedColor: #0095C5;
$button-mobile-homepageColor: #141F2C38;
$noticecolor-flight: #F97134;
$flight-alertbgcolor: #FDB7150F;
$notice-border: #fee8de;
$secure-color: #dbf6b0;
$flight-placeholder: #B0C2D7;
$calendardaysColor:#B0C2D7;
$table-border-bookedflight: #F3F3F3;
$banner-bg-color-bookeflight: #2d3e52bf;
$booked-tablefillcolor: #969696;
$price-default-text: #B1B1B1;
$border-red-color:#FF4019;
$bg-red-color:#FFEDE9;
$bg-orange-color: #FFF2EB;
$bg-green-color: #F6FEEB;
$button-hovercolor: #E8F9FE;
$price-disabled-color: #D9D9D9;
$covid19-anchortext: #FFEA7E;
