.multi-deals-container{
    margin-top: $upgrade-box; 
    .deal-row{
        @include xlg{
            margin: 0 -10px;
        } 
        @include rwd(1500){
            margin: 0 -15px;
        }      
    }   

    @include xxlg{
        .container{
            padding-left: 0;
            padding-right: 0;
        }
    }

    .svg-deals{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        svg{
            .a{
                isolation: isolate;
            }
            .b{
                fill: $whiteColor;
            }
        }
    }
}
.flex-col{
   flex: 1 1 100%;
   max-width: 100%;
    padding: 0 12px;
    @include md{
        max-width: 50%;
    }
    @include xl{
        flex: 0 0 30%;
        max-width: 30%;
    }
    @include xlg{
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}
.flex-col:last-child{
    max-width: 100%;
    margin-top: $paddingtop - 5px;
    @include md{
        margin-top: $paddingtop + 10px;
    }
    @include xl{
    flex: 0 0 40%;
    max-width: 38%;
    margin-top: 0px;
    margin-left: $paddingleft - 3px;
    }
    @include xlg{
    flex: 0 0 33.323%;
    max-width: 33.33%;
    margin-left: 0;
    }
}
.multi-trips-container{
    display: flex;
    flex-wrap: wrap;
}
.flight-deal-option,
.family-deal,
.cheap-deal,
.travelsafe-info-section,
.travelabout-info-section{
    position: relative;
    .img-section{
        img{
            width: 100%;
            border-radius: $border-radius + 5px;
        }
    }   
}
.flight-deal-option{
    margin-bottom: $paddingtop - 5px;
    flex: 0 0 100%;
    max-width: 100%;
    @include md{
        margin-bottom: $paddingtop;
    }
    @include xlg{
        margin-bottom:  $paddingtop + 20px;
    }
    .svg-deals{ 
       align-items: center;
       margin-right: $paddingtop + 10px;
       justify-content: flex-end;
       @include md{
           margin-top: 0;
           align-items: unset;
        
           margin-right:  $paddingtop + 5px;
       }
        @include lg{
           
            margin-top:  $paddingtop - 2px; 
        }    
       @include xl{
           margin-top: 0;
           justify-content: flex-end;
           margin-right: $paddingtop;
       }
       @include xlg{
        margin-top: $paddingtop;
        justify-content: unset;
       }
       @include rwd(1600){
        margin-right: $paddingtop + 20px;
       }
        svg{
            max-width: 65%;
            @include rwd(411){
                max-width: 75%;
            }
            @include rwd(540){
                max-width: 100%;
            }
            @include md{
                max-width: 73%;
            }
            @include lg{
                max-width: 90%;
            }
            @include xl{
                max-width: 72%;
               
            }
            @include xlg{
                max-width: 94%;
            }
            @include rwd(1600){
                max-width: 100%;
            }
        }
    }
}
.family-deal{
    .svg-deals{
        margin-right: $paddingtop + 10px; 
        justify-content: flex-end;
        @include rwd(520){
            justify-content: center;
        }
        @include md{
            margin-right: $paddingtop - 15px;
        }
        @include lg{
            margin-right: $paddingtop + 10px; 
        }
        @include xl{
            margin-right: 0px;
        }
        @include xlg{
            margin-right: $paddingtop - 3px;
        }
        @include rwd(1600){
            margin-right: $paddingtop + 10px; 
        }
     svg{
         max-width: 68%;
         @include rwd(411){
            max-width: 80%;
         }
         @include rwd(540){
            max-width: 100%;
         }
        @include md{
            max-width: 75%;
        }    
        @include lg{
            max-width: 100%;
        }  
        @include xl{
            max-width: 66%;
        }   
         @include xlg{
            max-width: 87%;
        }
        @include rwd(1600){
            max-width: 100%;
        }
     }   
    }
}
.cheap-deal{
    .svg-deals{
        margin-right: unset;
        left: 0;
        justify-content: center;
        margin-right: $paddingtop - 10px;
        svg{
            max-width: 48%;
            @include rwd(411){
                max-width: 62%;
             }
            @include rwd(540){
                max-width: 100%;
            }
            @include md{
                max-width: 54.5%;
            }
            @include lg{
                max-width: 100%;
            }    
            @include xl{
                max-width: 53%;
            }     
             @include xlg{
                max-width: 51%;
            }
            @include rwd(1600){
                max-width: 100%;
            }
        }     
    }
}
.travelsafe-info-section{
    .img-section{
        height: 100%;
        display: flex;
        .travelsafe-desktop{
            display: none;
            @include md{
                display: block;
            }
        }
        .travelsafe-mobile{
            display: block;
            @include md{
                display: none;
            }
        }
    }
    .svg-deals{
        left: 0;
        margin-right: $paddingtop + 10px;
        justify-content: flex-end;
        margin-top: 0;
        align-items: center;
        @include md{
            margin-top: 0;
            margin-right: 0;
            justify-content: center;
            align-items: unset;
        }
        @include lg{
            margin-top: $paddingtop - 8px;
        }
        @include xl{
            margin-top: -3px;
        }
        @include xlg{
            margin-top: $paddingtop ;
        }
        svg{
            max-width: 50%;
            @include md{
                max-width: 74%;
            }
            @include xl{
                max-width: 76%;
            }
            @include xlg{
                max-width: 80%;
            }
            @include rwd(1600){
                max-width: 100%;
            }
        }
    }
}
.travelabout-info-section{
    background: $primary-color;
    height:  $uspboxHeight + 237px;
    margin: 0 15px;
    border-radius: $border-radius + 5px;
    @include xl{
        margin: 0;
        height: 328px;
    }
    @include xlg{
        background: none;
        margin: 0;
        border-radius: 0;
        height: unset;
    }
    .img-section{
        height: 100%;
        img{
            height: 100%;
           display: none;
           @include xlg{
               display: block;
           }
        }
    }
    .svg-deals{
        left: 0;
        margin-right: 0;
        justify-content: center;
        margin-top: $paddingtop - 10px;
        @include md{
            margin-top: $paddingleft + 10px ;
        }
        @include xl{
            margin-top: $paddingleft - 4px ;
        }
        @include xlg{
            margin-top: $paddingtop ;
        }
      svg{
          max-width: 85%;
          @include rwd(520){
              max-width: 100%;
          }
          @include xl{
              max-width: 85%;
          }
          @include xlg{
            max-width: 380px;
        }
          @include rwd(1600){
            max-width: 100%;
        }         
      }  
    }
    .aboutus_content{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 17%;
        margin: 0 auto;
        display: block;
        text-align: center;
        max-width: $uspboxHeight + 72px;
        @include rwd(540){
            max-width: $uspboxHeight + 175px;
        }
        @include md{
            max-width: $uspboxHeight + 355px;
            top: 30%;
        }
        @include lg{
            max-width: 585px;
        }
        @include xl{
            max-width: $uspboxHeight + 158px;
            top: 25%;
        }
        @include xlg{
            max-width: $uspboxHeight + 123px;
            top: 23%;
        }
        @include rwd(1500){
            max-width: $uspboxHeight + 149px;
        }     
    p{
        font-size: 13px;
        color: $whiteColor;
        font-weight: 400;
        @include rwd(540){
            font-size: 15px;
        }
        @include xl{
            font-size: 12px;
        }
        @include xlg{
            font-size: 13.5px;
        }
        @include rwd(1500){
            font-size: 15px;
        }
    }    
    }
    .col-about_button{
        display: flex;
        justify-content: center;
        margin-top: $paddingtop - 5px;
        @include md{
            margin-top: $paddingtop + 20px;
        }
        @include lg{
            margin-top: $paddingtop + 20px;
        }
        @include xl{
            margin-top: $paddingtop - 5px;
        }
        @include xlg{
            margin-top: $paddingtop + 5px;
        }
        @include rwd(1600){
            margin-top: $paddingtop + 20px;
        }
      .butn{
        height: $upgrade-box;
        line-height: $upgrade-box;
        letter-spacing: 1.05px;
        border: 0;
        width: 203px;
        font-size: 14px; 
        border-radius: $border-radius + 3px;
        @include lg{
            font-size: 14px;
        }
          @include xl{
            font-size: 13px;   
            width: $uspboxHeight - 9px;           
          }
          @include xlg{            
            font-size: 14px;            
          }          
      }  
    }
}
.trips-option{
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    margin-bottom: $paddingtop - 5px;
    @include md{
        margin-bottom: 0;
    }
    .cheap-deal{
        padding-left: $paddingleft - 3px;  
        width: 100%;   
        flex: 0 0 50%;
        max-width: 50%;   
    }
    .family-deal{
        padding-right: $paddingleft - 3px;
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.img-banner{
    position: absolute;
    height: 100%;
    display: flex;
    width: calc(100% - 523px);
    display: none;
    @include rwd(540){
        width: calc(100% - 55px);
        display: block;
    }
    @include md{
        width: calc(100% - 260px);
       
    }
    @include lg{
        width: calc(100% - 435px);
    }
    @include xl{
        width: calc(100% - 100px);
    }
    @include xlg{
        display: none;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom right;
    }
    &.left{
        left: 0;
        bottom: 0;
      img{
          position: absolute;
          bottom: 0;
          max-height: 80%;
      }  
    }
    &.right
    {
        right: 0;
        bottom: 0;
        img{
            margin-left: auto;
            object-position: bottom left;
        }
    }
   
}
.img-bannermen-mob{
    position: absolute;
    left: 0;
    display: block;
    @include rwd(540){
        display: none;
    }
}
.img-bannerlady-mob{
    position: absolute;
    right: 0;
    display: block;
    @include rwd(540){
        display: none;
    }
}
.family-deal,
.cheap-deal{
    .svg-deals{
        margin-top: 0px;
        @include rwd(411){
            margin-top: $paddingleft - 8px;
        }
        @include rwd(540){
            margin-top: $paddingleft + 15px;
        }
        @include md{
            margin-top:0;
        }
        @include lg{
            margin-top: $paddingleft - 2px;
        }
        @include xl{
            margin-top:0;
        }
        @include xlg{
            margin-top: $paddingleft - 5px;
        }
        @include rwd(1600){
            margin-top: $paddingtop !important;
        }     
    }
    .img-section{
        height: 100%;
        width: 100%;
    }
}
.flight-option{
    position: relative;
    flex: 0 0 100%;
    @include xl{
        margin-bottom: 19.5px;
    }
}

.flight-deals{
    margin-left: 12px;
    margin-right: 0 !important;
   @include rwd(411){
    margin-top: 5px !important; 
     }
      svg{
        max-width: 48%;    
        @include rwd(411){
            max-width: 52% !important;
         }
        }
    } 