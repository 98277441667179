.svg-icon-reskin {
    width: $upgrade-box - 32px;
}

.flight-carousel {
    .caroufredsel_wrapper2 {
        position: relative;
        height: 140px !important;
        // width: 100% !important;
    }
    .flights_list_carousel,
    .flights2_list_carousel {
        ul {
            display: flex;
            position: relative !important;
            padding: 5px 25px 0 15px;
        }
    }
    li {
        .fch {
            img {
                padding: 0 3px;
                opacity: 1;
            }
        }
    }
    .ftable {
        flex-direction: column;
    }
    .flights2prev,
    .flightsprev {
        position: relative;
        background: none;
        top: 0;
        width: 0;
        height: 0;
        float: left;
        margin-left: 0;
        left: 0;
        svg {
            width: 13px;
            height: 13px;
            position: absolute;
            top: -90px;
            bottom: 0;
            @include sm {
                left: $paddingleft - 4px;
            }
            @include xl {
                left: -2px;
            }
        }
    }
    .flights2next,
    .flightsnext {
        position: relative;
        background: none;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        float: right;
        margin-right: 0;
        svg {
            width: 13px;
            height: 13px;
            position: absolute;
            top: -90px;
            bottom: 0;
            @include sm {
                right: $paddingleft - 4px;
            }
            @include xl {
                right: 0px;
            }
        }
    }
    .flightsgrid {
        width: 100%;
        background: $whiteColor;
        overflow: hidden;
    }
    .flights_wrapper {
        width: 100%;
        height: 100%;
        float: left;
        padding: 0;
        background-color: transparent;
    }
    .flights2_wrapper {
        width: 50%;
        height: 100%;
        float: right;
        padding: 0;
        background-color: transparent;
    }
}

.flight-carousel {
    flex: 1 0 0;
    margin-bottom: $paddingtop + 40px;
    width: 100%;
    .butn {
        @include md {
            margin-top: $paddingright - 3px;
            height: $upgrade-box - 25px;
            line-height: $upgrade-box - 25px;
            font-size: 15px;
            color: $whiteColor;
        }
    }
}

.flights_list_carousel,
.flights2_list_carousel {
    background-color: transparent;
    margin: 0;
    width: 100%;
    overflow: hidden;
    position: relative;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: block;
    }
    li {
        font-size: 40px;
        color: #ccc;
        text-align: center;
        background-color: transparent;
        border-right: 0;
        height: 240px;
        padding: 0;
        margin: 0 0;
        display: block;
        float: left;
        line-height: 10px;
        text-align: center;
        width: auto;
        margin-right: 30px;
        img {
            width: 100%;
            max-width: 60px;
            height: 100%;
        }
    }
}

.ftable li {
    clear: both;
    width: 100%;
    height: 100% !important;
    border-right: 0;
    font-size: 18px;
    border-bottom: 0;
}

.airline-box {
    .image-holder {
        max-width: 125px;
        width: 100%;
        margin: 0 auto;
        display: block;
    }
    .destination-textpanel {
        margin-bottom: 10px;
        display: flex;
        .flight-info-panel {
            .flightpath {
                font-size: 9px;
                color: $primary-color;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 0.10em;
                margin-bottom: 0;
            }
            .destination {
                margin-bottom: 2px;
                font-size: 14px;
                text-align: left;
                color: $dark-sixth-color;
                ;
            }
        }
        .price-panel {
            .price-box {
                font-size: 18px;
            }
        }
    }
}

#top_control_section {
    &.black-friday-flight {
        .gradient-container {
            .gradient-section {
                @include xl {
                    height: 165px;
                    background: transparent linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.65) 94%, #fff 100%, #fff 100%) 0% 0% no-repeat padding-box;
                }
            }
        }
    }
}

#slideshow-banner {
    .black-friday-flight {
        .swiper-infosection {
            justify-content: space-between;
            .swiper-textbox {
                .svg-banner-text {
                    svg {
                        width: 90%;
                        height: 100%;
                        max-width: unset;
                        @include xlg {
                            width: 100%;
                        }
                    }
                }
            }
            .swiper-image {
                display: flex;
                justify-content: flex-end;
                img {
                    max-width: 90%;
                    margin-right: 0px;
                    margin-top: 53px;
                    @include xlg {
                        margin-top: 70px;
                    }
                }
            }
        }
    }
}