.flight-homepage{
    &#testimonials-slider{
        overflow: unset;
        margin-bottom: 120px;
        @include xl{
            overflow: hidden;
            margin-bottom: 50px;
        }
    }
} 
#testimonials-slider{
    .flex-row{
        display: block;
        @include xl{
            display: flex;
        }
       
    }
}

