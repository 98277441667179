//normal sparphone
$screen-smarphone-min: 374px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;
$screen-xl-max: 1199px;
$screen-xlg-min: 1400px;
$screen-xxlg-min: 1600px;
$browser-context: 12; // Default

@mixin smarphone {
    @media (min-width: #{$screen-smarphone-min}) {
        @content;
    }
 }

@mixin sm {
   @media (min-width: #{$screen-sm-min}) {
       @content;
   }
}

// Medium devices
@mixin md {
   @media (min-width: #{$screen-md-min}) {
       @content;
   }
}

// Large devices
@mixin lg {
   @media (min-width: #{$screen-lg-min}) {
       @content;
   }
}

// Extra large devices
@mixin xl {
   @media (min-width: #{$screen-xl-min}) {
       @content;
   }
}
@mixin xlg {
    @media (min-width: #{$screen-xlg-min}) {
        @content;
    }
 }

 @mixin xxlg {
    @media (min-width: #{$screen-xxlg-min}) {
        @content;
    }
 }

// Custom devices
@mixin rwd($screen) {
    @media (min-width: $screen+'px') {
        @content;
    }
 }
 @mixin maxwd($screen) {
    @media (max-width: $screen+'px') {
        @content;
    }
 }
 @mixin mediaHeight($screen, $md) {

    @if $md == 'min' {
    @media (min-height: $screen+'px') {
        @content;
    }
    }
    @media (max-height: $screen+'px') {
        @content;
    }
 }


 @function em($pixels, $context: $browser-context) {
   @return #{$pixels/$context}em;
 }
 @mixin flex {
     display: flex;
 }
 @mixin bolderFont($font) {
     font-weight: 900;
     font-family: $font;
 }
//  @mixin border-right($width, $color) {
//     border-right: $width solid $color;
// }
// @mixin border-bottom($color, $opacity: 0.5) {
//     border-bottom: 1px solid  rgba($color, $opacity);
// }
// @mixin border-top($color, $opacity: 0.5) {
//     border-top: 1px solid  rgba($color, $opacity);
// }
@mixin border($color, $opacity: 0.5) {
    border: 1px solid  rgba($color, $opacity);
}
@mixin border-dynamic($property_name, $borderSize, $color, $opacity: 0.5) {
    border-#{$property_name}: $borderSize solid rgba($color, $opacity);
}
@mixin border-right-opacity($color, $opacity: 0.5) {
    border-right: 1px solid  rgba($color, $opacity);
}
@mixin background-colour($color, $opacity: 0.5) {
    background: rgba($color, $opacity);
}
@mixin passenger-fnt($fontsize, $fntClr) {
    font-size: $fontsize;
    color: $fntClr;
    font-weight: bold;
}
@mixin graphLabelClr($color, $font-weight, $fill) {
    @if $fill == true {
        fill: $color;
       
    }
   @else {
        color: $color;
    }
    font-weight: $font-weight;
    letter-spacing: 0.1em;
}
@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}