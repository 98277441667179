.homepage-container {
    @include xlg {
        max-width: 1318px;
        padding: 0;
    }
}
.open-extraoptions {
    .guestnumber {
        .guest-group {
            .bootstrap-touchspin {
                align-items: center;
            }
        }
    }
    .form-extraoptions#formextras {
        &.active {
            display: none !important;
            @include xl {
                display: flex !important;
                transition: 0.25s ease-in-out opacity;
                z-index: 1;
            }
            &.showmobileform {
                display: block !important;
                @include xl {
                    display: none !important;
                }
            }
        }
    }
    .tuform-field {
        &#deptview {
            .form-icon {
                @include border-dynamic(right, 1px, $baggage-image-icon-light-blue, 1);
                position: absolute;
                right: 5px;
                bottom: 9px;
                top: 34px;
                display: flex;
                align-items: center;
                @include xl {
                    border-right: 0;
                    top: 0;
                    bottom: 0;
                }
                svg {
                    margin-right: $paddingleft - 2px;
                }
            }
        }
        &#departingfield {
            .form-icon {
                border-right: 0;
                @include rwd(600) {
                    @include border-dynamic(right, 1px, $baggage-image-icon-light-blue, 1);
                    position: absolute;
                    right: 5px;
                    bottom: 9px;
                    top: 34px;
                    display: flex;
                    align-items: center;
                }
                @include xl {
                    border-right: 0;
                    right: unset;
                    bottom: 0;
                    top: 0;
                }
                svg {
                    display: none;
                    @include xl {
                        display: block;
                    }
                }
            }
        }
        .form-icon {
            @include xl {
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
            }
        }
        &.calendar-field {
            cursor: pointer;
        }
    }
    .tabnew-list {
        li {
            @include xl {
                margin-bottom: 1px;
            }
            &.active {
                a {
                    &::before {
                        @include xl {
                            content: '';
                            border-left: 14px solid transparent;
                            border-right: 14px solid transparent;
                            border-top: 14px solid #fff;
                            bottom: -15px;
                            position: absolute;
                            transform: translate(1%, 0%);
                            left: -4px;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
    .col-button {
        @include xl {
            flex: 0 0 100px;
            max-width: 100px;
        }
    }
    &.search-control-container {
        .form-input-controls {
            border: 0;
            @include xl {
                border: 1px solid $primary-color;
                border-top-left-radius: 10px;
            }
            .checkbox {
                &::before {
                }
                &.checked {
                    &::before {
                        background: $whiteColor;
                        content: url('../../images/tickblue-blue.svg');
                        line-height: 0 ;
                        width: 20px;
                        height: 20px;
                        @include xl {
                            background: $primary-color;
                            content: url('../../images/tick-icon.svg');
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
        }
        .col-inputfield,
        .col-passenger,
        .col-calendar {
            @include xl {
                padding: 8px 0;
            }
        }
        .form-row {
            @include xl {
                border-top-left-radius: 8.4px;
                flex-basis: calc(100% - 100px);
                flex: 0 0 calc(100% - 100px);
            }
            .col-inputfield {
                &.return {
                    @include xl {
                        max-width: 24%;
                    }
                    @include xl {
                        max-width: 25%;
                    }
                }
            }
            .col-passenger {
                @include xl {
                    max-width: 16.5%;
                }
                @include xlg {
                    max-width: 15.5%;
                }
            }
            .col-calendar {
                .col-50:first-child {
                    @include xl {
                        flex: 0 0 53%;
                        max-width: 53%;
                    }
                }
                .col-50:last-child {
                    @include xl {
                        flex: 0 0 50.56%;
                        max-width: 50.56%;
                    }
                }
            }
        }
        .tuform-field input {
            height: $upgrade-box;
            @include xl {
                height: auto;
            }
        }
        .tuform-field {
            .format_field {
                height: 56px;
                @include xl {
                    height: auto;
                }
            }
        }
    }
    .custom-selectdrop {
        height: $upgrade-box;
        @include xl {
            height: auto;
        }
        &::before {
            width: $upgrade-box - 34px;
            right: $upgrade-box - 32px;
            top: 1px;
            @include xl {
                width: $upgrade-box - 37px;
                right: -20px;
                top: 0;
            }
            @include xlg {
                width: $upgrade-box - 35px;
                right: -14px;
                top: 0;
            }
        }
    }
    .form-extraoptions {
        padding: 0 15px;
        @include xl {
            padding: 0;
        }
        &#formextras {
            @include xl {
                margin-right: $paddingleft;
            }
        }
    }
    .form-extrascontainer {
        padding: 0;
    }

    .col-calendar {
        .tuform-field#returnview {
            input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
        .tuform-field#deptview {
            input {
                border-right: 0;
            }
        }
        .form-icon {
            right: $upgrade-box - 37px;
            top: 34px;
            right: 0;
            @include xl {
                top: 0;
            }
            svg {
                width: $upgrade-box - 32px;
            }
        }
    }
    .direct-field {
        margin-right: $paddingtop + 10px;
        label {
            font-size: em(12);
            line-height: 19px;
        }
        .checkbox {
            &::before {
                @include xl {
                    width: $paddingleft;
                    height: $paddingleft;
                }
            }
        }
    }
    .close_button {
        @include xl {
            display: flex;
            align-items: center;
        }
        a {
            font-weight: 600;
            letter-spacing: 0.6px;
            @include xl {
                padding: 0;
            }
            svg {
                opacity: 0;
            }
        }
    }
    .select-wrap {
        display: flex;
        align-items: center;
        margin-bottom: $paddingtop;
        @include xl {
            margin-right: $paddingtop + 10px;
            margin-bottom: 0px;
        }
        .selectric {
            padding-top: $paddingtop - 10px;
            padding-bottom: $paddingtop - 10px;
            .button {
                @include xl {
                    top: 1px;
                    right: 0;
                }
            }
        }
        .selectric-wrapper {
            width: 100%;
            @include xl {
                margin-right: 0;
            }
        }
    }
    .form-input-controls {
        .form-row::before {
            @include xl {
                content: '';
                background: $fourth-color;
                width: $paddingleft;
                height: $paddingleft;
                position: absolute;
                z-index: -1;
                margin-top: -1px;
            }
        }
    }
    .tabnew-list {
        @include xl {
            margin-left: $paddingleft - 14px;
        }
    }
    .more_btncontainer {
        height: 54px;
        line-height: 54px;
        font-weight: 700;
        letter-spacing: 0.82px;
        background-color: $button-mobile-homepageColor;
        @include xl {
            height: auto;
            line-height: unset;
            background-color: $whiteColor;
        }
        &.invisible {
            display: flex;
            @include xl {
                display: none;
            }
        }
        .arrow_down {
            top: 1px;
            @include xl {
                top: 6px;
            }
            svg {
                width: 16px;
            }
        }
    }
    .btn-container {
        .btn2style {
            font-size: em(11);
            font-weight: 700;
            letter-spacing: 0.82px;
            background-color: $button-mobile-homepageColor;
            &:hover,
            &:focus {
                color: $whiteColor;
            }
        }
    }
    .submit-button {
        height: $upgrade-box + 4px;
        line-height: $upgrade-box + 4px;
        border-radius: 8.3px;
        background: $FlightGreenButton;
        @include xl {
            height: 100%;
            border-radius: 8.3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.flight-deal {
    &.destination-section {
        margin-top: $upgrade-box;
        .destination-box {
            border: 1px solid $primary-color;
            .flight-info-panel {
                h4 {
                    color: $fourth-color;
                    font-size: 8px;
                    font-weight: 700;
                    letter-spacing: 0.6px;
                }
                h2 {
                    font-size: 15px;
                    margin-bottom: $paddingleft - 13px;
                }
            }
            .price-panel {
                .from-box {
                    color: $fourth-color;
                    font-weight: 700;
                    letter-spacing: 0.75px;
                }
            }
        }
    }
    .svg-bound-icon {
        position: absolute;
        left: -7px;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        svg {
            width: $upgrade-box - 25px;
            height: $upgrade-box - 25px;
            .a {
                fill: $primary-color;
            }
        }
    }
}
.infinite-carousel {
    .flight-carousel {
        .flightsprev {
            left: -3px;
            @include rwd(600) {
                left: -14px;
            }
            @include xl {
                left: 0;
            }
        }
        .flightsnext {
            margin-right: 11px;
            @include rwd(600) {
                margin-right: -12px;
            }
            @include xl {
                margin-right: 0;
            }
        }
    }
}
#form-section {
    background-image: url('../assets/homepage/mobile_bgimage.png');
    height: 100%;
    width: 100%;
    // background-size: 133% 119%;
    background-size: cover;
    background-position: bottom left;
    background-repeat: no-repeat;
    @include md {
        background-position: 134% 75%;
    }
    @include xl {
        background-image: none;
    }
}

#ddlAdult,
#ddlChild,
#ddlInfant {
    font-size: 1.25em;
    color: #01b7f2;
    margin: 0 8px;
    font-weight: 400;
    height: 26px;
}
.arrow_active {
    fill: #01b7f2;
}

.arrow_disabled {
    fill: #CCF0FB;
}
.guest-bottombutton{
    & > a{
        background-color: #98CE44;
        color: #FFFFFF;
        border-radius: 7px;
        width: 90px;
        height: 34px;  
        text-align: center;      
        font-size: 10px;
        font-weight: 600;
        justify-content: center;
        line-height: 28px;
        font-size: 10px ;
        cursor: pointer;
       
    }
    a:hover,
    a:focus{
        background-color: #7fb231;
        color: $whiteColor ;
        
    }
}


#guestbox{
    position: absolute;
    top: 40px;
    width: 100%;
    left: 0px;
    margin: 0;
    @include md{
    width: 239px;
    }
    @include xl{
        margin-left: 15px;
        top: -6px;           
        left: -41px;
    }
}