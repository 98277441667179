.subscribition-box{
    margin-top: 50px;
    background-image: url('../assets/homepage/banner-_subscribe_mobile2.png');
    width: 100%;
    height: $uspboxHeight + 72px;  
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 20%;
    position: relative;
    display: flex;
    @include rwd(540){
        background-image: url('../assets/homepage/subscribe_banner_mobile.png');
        height: $uspboxHeight + 4px;  
        background-position: 78% 20%;
    }
    @include lg{
        height: $uspboxHeight - 42px;
        background-position: 85% 20%;
        background-image: url('../assets/homepage/subscribe_banner_desktop.png');
        align-items: center;
    }
}

.subscribe-container{  
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: $paddingleft;
   @include lg{
    justify-content: space-between;
    flex-direction: row;
    margin-right: $uspboxHeight + 50px;
    margin-top: 0;
   }
   .svg-bannertext{
    svg{
     width: $uspboxHeight + 130px;
     @include lg{
         width: 100%;  
     }
     .a{
         isolation:isolate;
     }
     .b{
         fill:$whiteColor;
     }
    }
 }
  .subscribe-text-section{
    max-width: $uspboxHeight + 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align-last: center;
    margin-bottom: $paddingleft;
    @include rwd(540){
        max-width: $uspboxHeight + 300px;
    }
    @include md{
        max-width: $uspboxHeight + 350px;
    }
      @include lg{
         text-align-last: left;
         margin-bottom: 0;
         align-items: flex-start;
      }
      @include xlg{
          max-width: $uspboxHeight + 500px;    
      }
      @include rwd(1500){
        max-width: 100%;
      }
      p{
        margin-top: $paddingtop - 10px;
        font-size: 14px;
        color: $whiteColor;
        font-weight: 400;
        margin-bottom: $paddingleft;
        line-height: 18px;
        @include rwd(540){
            margin-bottom: 0;
        }
        @include md{
            font-size: 15px;
        }
        @include lg{
            font-size: 16px;          
        }
      }
  } 
  .subscribe-button{
    @include xl{
        margin-right: $paddingtop + 15px;
    }
    @include xlg{
        margin-right: $paddingtop + 100px;
    }  
    @include rwd(1500){
        margin-right: $paddingtop + 40px;
    } 
    .butn{
        height: $upgrade-box - 2px;
        line-height: $upgrade-box - 2px;
        font-size: 13px;
        letter-spacing: 1.05px;
        width: 191px;
        border: 0;
        font-weight: 700;
        border-radius: $border-radius + 3px;
        @include lg{
            height: $upgrade-box + 6px;
            line-height: $upgrade-box + 6px;
            font-size: 14px;
        }
    }
  } 
}

